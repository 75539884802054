import { NOTIFICATIONS } from "../constants";
import { instance } from "../../axios";

let createNotificationAction = (body) => {
  return (dispatch) => {
    dispatch({
      type: NOTIFICATIONS.CREATE_NOTIFICATION,
    });
    instance
      .post("/notification", body)
      .then((results) => {
        dispatch({
          type: NOTIFICATIONS.CREATE_NOTIFICATION_SUCCESS,
          payload: results.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: NOTIFICATIONS.CREATE_NOTIFICATION_FAILURE,
          payload: error,
        });
      });
  };
};

let updateNotificationAction = (body) => {
  return (dispatch) => {
    dispatch({
      type: NOTIFICATIONS.UPDATE_NOTIFICATION,
    });
    instance
      .post("/notification/" + body.id, body)
      .then((results) => {
        dispatch({
          type: NOTIFICATIONS.UPDATE_NOTIFICATION_SUCCESS,
          payload: results.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: NOTIFICATIONS.UPDATE_NOTIFICATION_FAILURE,
          payload: error,
        });
      });
  };
};

let deleteNotificationAction = (id, limit, offset) => {
  return (dispatch) => {
    dispatch({
      type: NOTIFICATIONS.DELETE_NOTIFICATION,
    });
    instance
      .delete("/notification/" + id)
      .then(() => {
        dispatch({
          type: NOTIFICATIONS.DELETE_NOTIFICATION_SUCCESS,
        });
        dispatch(getNotificationsAction(limit, offset));
      })
      .catch((error) => {
        dispatch({
          type: NOTIFICATIONS.DELETE_NOTIFICATION_FAILURE,
          payload: error,
        });
      });
  };
};

let getNotificationsAction = (limit, offset) => {
  return (dispatch) => {
    dispatch({
      type: NOTIFICATIONS.GET_NOTIFICATIONS,
    });
    instance
      .get(`/notification?limit=${limit}&offset=${offset}`)
      .then((results) => {
        dispatch({
          type: NOTIFICATIONS.GET_NOTIFICATIONS_SUCCESS,
          payload: results.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: NOTIFICATIONS.GET_NOTIFICATIONS_FAILURE,
          payload: error,
        });
      });
  };
};

let setNotificationAction = (notification) => {
  return (dispatch) => {
    dispatch({
      type: NOTIFICATIONS.SET_NOTIFICATION,
      payload: notification,
    });
  };
};

let cleanNotificationAction = () => {
  return (dispatch) => {
    dispatch({
      type: NOTIFICATIONS.CLEAN_NOTIFICATIONS,
    });
  };
};

export {
  createNotificationAction,
  getNotificationsAction,
  cleanNotificationAction,
  deleteNotificationAction,
  setNotificationAction,
  updateNotificationAction,
};
