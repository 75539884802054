import { ADVERTISING } from "../constants";

const initialState = {
  advertising: null,
  isLoadingAdvertising: false,
  isLoadingCleanAdvertisingPicture: false,
  error: null,
};

const advertisingReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ADVERTISING.POST_ADVERTISING:
      return {
        ...state,
        isLoadingAdvertising: true,
      };
    case ADVERTISING.POST_ADVERTISING_FAILURE:
      return {
        ...state,
        isLoadingAdvertising: false,
        error: action.payload,
      };
    case ADVERTISING.POST_ADVERTISING_SUCCESS:
      return {
        ...state,
        isLoadingAdvertising: false,
        advertising: action.payload,
        error: null,
      };
    case ADVERTISING.GET_ADVERTISING:
      return {
        ...state,
        isLoadingAdvertising: true,
      };
    case ADVERTISING.GET_ADVERTISING_FAILURE:
      return {
        ...state,
        isLoadingAdvertising: false,
        error: action.payload,
      };
    case ADVERTISING.GET_ADVERTISING_SUCCESS:
      return {
        ...state,
        isLoadingAdvertising: false,
        advertising: action.payload,
        error: null,
      };
    case ADVERTISING.CLEAN_ADVERTISING_PICTURE:
      return {
        ...state,
        isLoadingCleanAdvertisingPicture: true,
      };
    case ADVERTISING.CLEAN_ADVERTISING_PICTURE_FAILURE:
      return {
        ...state,
        isLoadingCleanAdvertisingPicture: false,
        error: action.payload,
      };
    case ADVERTISING.CLEAN_ADVERTISING_PICTURE_SUCCESS:
      return {
        ...state,
        isLoadingCleanAdvertisingPicture: false,
        advertising: action.payload,
        error: null,
      };
    case ADVERTISING.CLEAN_ADVERTISING:
      return {
        ...state,
        advertising: null,
      };
    default:
      return state;
  }
};

export default advertisingReducer;
