import React from "react";
import { makeStyles } from "@mui/styles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

const useStyles = makeStyles((theme) => ({
  selectField: {
    "& label": {
      color: "rgba(255, 255, 255, 0.7)",
    },
    "& label.Mui-disabled": {
      color: "rgba(255, 255, 255, 0.4)",
    },
    "& label.Mui-focused": {
      color: "rgba(255, 255, 255, 0.7)",
    },
    "& .MuiInputBase-input": {
      color: "rgba(255, 255, 255, 0.7)",
    },
    "& .MuiFilledInput-underline:before": {
      borderBottomColor: "rgba(255, 255, 255, 0.7)",
    },
    "& .MuiFilledInput-underline:hover:not(.Mui-disabled):before": {
      borderBottomColor: "rgba(255, 255, 255, 1)",
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: "rgba(255, 255, 255, 0.7)",
    },
    "& .MuiFilledInput-root": {
      backgroundColor: "rgba(255, 255, 255, 0.2)",
    },
    "& .MuiFilledInput-root:not(.Mui-disabled):hover": {
      backgroundColor: "rgba(255, 255, 255, 0.3)",
    },
  },
}));

const CustomSelectField = ({
  margin,
  onChange,
  value,
  defaultValues,
  title,
  id,
  disabled,
}) => {
  const classes = useStyles();
  const handleChange = (event) => {
    onChange(event.target.value);
  };
  return (
    <FormControl
      className={classes.selectField}
      fullWidth
      margin={margin}
      disabled={disabled}
    >
      <InputLabel id={id + "-label"}>{title}</InputLabel>
      <Select
        labelId={id + "-select-label"}
        id={id + "-select"}
        value={value}
        label={title}
        variant="filled"
        onChange={handleChange}
      >
        {defaultValues.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomSelectField;
