import { USER } from "../constants";
import { instance } from "../../axios";

let getUsersAction = () => {
  return (dispatch) => {
    dispatch({
      type: USER.GET_USERS,
    });
    instance
      .get("/user")
      .then((results) => {
        dispatch({
          type: USER.GET_USERS_SUCCESS,
          payload: results.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: USER.GET_USERS_FAILURE,
          payload: error,
        });
      });
  };
};

let setUserAction = (user) => {
  return (dispatch) => {
    dispatch({
      type: USER.SET_USER,
      payload: user,
    });
  };
};

let cleanUserAction = () => {
  return (dispatch) => {
    dispatch({
      type: USER.CLEAN_USER,
    });
  };
};

export { setUserAction, cleanUserAction, getUsersAction };
