import React from "react";
import { makeStyles } from "@mui/styles";
import { TextField } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  textField: {
    "& label": {
      color: "rgba(255, 255, 255, 0.7)",
    },
    "& label.Mui-focused": {
      color: "rgba(255, 255, 255, 0.7)",
    },
    "& .MuiInputBase-input": {
      color: "rgba(255, 255, 255, 0.7)",
    },
    "& .MuiFilledInput-underline:before": {
      borderBottomColor: "rgba(255, 255, 255, 0.7)",
    },
    "& .MuiFilledInput-underline:hover:before": {
      borderBottomColor: "rgba(255, 255, 255, 1)",
    },
    "& .MuiFilledInput-underline:hover:not(.Mui-disabled):before": {
      borderBottomColor: "rgba(255, 255, 255, 1)",
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: "rgba(255, 255, 255, 0.7)",
    },
    "& .MuiFilledInput-root": {
      backgroundColor: "rgba(255, 255, 255, 0.2)",
    },
    "& .MuiFilledInput-root:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.3)",
    },
  },
}));

const CustomTextField = ({
  margin,
  name,
  label,
  type,
  id,
  autoComplete,
  value,
  onChange,
  disabled,
  required,
  handleKeyDown,
}) => {
  const classes = useStyles();
  const handleChange = (event) => {
    onChange(event.target.value);
  };
  return (
    <TextField
      margin={margin}
      required={required}
      fullWidth
      name={name}
      disabled={disabled}
      variant="filled"
      label={label}
      value={value || ""}
      type={type}
      id={id}
      onChange={handleChange}
      autoComplete={autoComplete}
      onKeyDown={handleKeyDown}
      className={classes.textField}
    />
  );
};

export default CustomTextField;
