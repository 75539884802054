import { ADVERTISING } from "../constants";
import { instance } from "../../axios";

let updateAdvertisingAction = (formData) => {
  return (dispatch) => {
    dispatch({
      type: ADVERTISING.POST_ADVERTISING,
    });
    instance
      .post("/advertising/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((results) => {
        dispatch({
          type: ADVERTISING.POST_ADVERTISING_SUCCESS,
          payload: results.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: ADVERTISING.POST_ADVERTISING_FAILURE,
          payload: error,
        });
      });
  };
};

let getAdvertisingAction = () => {
  return (dispatch) => {
    dispatch({
      type: ADVERTISING.GET_ADVERTISING,
    });
    instance
      .get("/advertising")
      .then((results) => {
        dispatch({
          type: ADVERTISING.GET_ADVERTISING_SUCCESS,
          payload: results.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: ADVERTISING.GET_ADVERTISING_FAILURE,
          payload: error,
        });
      });
  };
};

let cleanAdvertisingPictureAction = () => {
  return (dispatch) => {
    dispatch({
      type: ADVERTISING.CLEAN_ADVERTISING_PICTURE,
    });
    instance
      .delete("/advertising/picture")
      .then((results) => {
        dispatch({
          type: ADVERTISING.CLEAN_ADVERTISING_PICTURE_SUCCESS,
          payload: results.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: ADVERTISING.CLEAN_ADVERTISING_PICTURE_FAILURE,
          payload: error,
        });
      });
  };
};

let cleanAdvertisingAction = () => {
  return (dispatch) => {
    dispatch({
      type: ADVERTISING.CLEAN_ADVERTISING,
    });
  };
};

export {
  updateAdvertisingAction,
  cleanAdvertisingAction,
  getAdvertisingAction,
  cleanAdvertisingPictureAction,
};
