import LocalizedStrings from "react-localization";
import en from "./en";
import es from "./es";

let strings = new LocalizedStrings({
  en: en,
  es: es,
});

let language = "en";
if (strings.getLanguage() === "en" || strings.getLanguage() === "es")
  language = strings.getLanguage();
strings.setLanguage("es");

export default strings;
