import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Rating,
  InputAdornment,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import { getFeedbacksAction } from "../redux/actions/feedback";
import moment from "moment";
import strings from "../i18n/language";

const Feedbacks = () => {
  const feedbacks = useSelector((state) => state.feedback.feedbacks);
  const dispatch = useDispatch();
  const feedbacksRef = useRef([]);

  useEffect(() => {
    dispatch(getFeedbacksAction());
  }, [dispatch]);

  useEffect(() => {
    if (feedbacks) feedbacksRef.current = feedbacks;
  }, [feedbacks]);

  const [searchQuery, setSearchQuery] = useState("");
  const filteredFeedbacks = feedbacksRef.current.filter(
    (feedback) =>
      feedback.message.toLowerCase().includes(searchQuery.toLowerCase()) ||
      feedback.User.displayName
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
  );
  return (
    <Paper sx={{ p: 2 }}>
      <TextField
        fullWidth
        variant="outlined"
        placeholder={strings.LABEL_FEEDBACKS_SEARCH}
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          sx: { borderRadius: 25 }, // Rounded corners for input field
        }}
        sx={{ mb: 2 }}
      />
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="Feedbacks table">
          <TableHead>
            <TableRow>
              <TableCell>{strings.LABEL_FEEDBACKS_HEADER_USER}</TableCell>
              <TableCell>{strings.LABEL_FEEDBACKS_HEADER_NOTE}</TableCell>
              <TableCell>{strings.LABEL_FEEDBACKS_HEADER_MESSAGE}</TableCell>
              <TableCell>{strings.LABEL_FEEDBACKS_HEADER_DATE}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredFeedbacks.map((feedback) => (
              <TableRow key={feedback.id}>
                <TableCell component="th" scope="row">
                  {feedback.User.displayName}
                </TableCell>
                <TableCell>
                  <Rating value={feedback.note} readOnly />
                </TableCell>
                <TableCell>{feedback.message}</TableCell>
                <TableCell>{moment(feedback.date).format("LL")}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default Feedbacks;
