// prettier-ignore
let dictionary = {
  LABEL_INPUT_USERNAME: 'Nombre de usuario',
  LABEL_INPUT_PASSWORD: 'Contraseña',
  LABEL_INPUT_NAME: 'Nombre',
  LABEL_INPUT_FACEBOOK_URL: 'URL de Facebook',
  LABEL_INPUT_INSTAGRAM_URL: 'URL de Instagram',
  LABEL_INPUT_GIFT_EXPIRATION: 'Expiración del regalo (días)',
  LABEL_INPUT_CARD_EXPIRATION: 'Expiración de la tarjeta (días)',
  LABEL_INPUT_IMAGE: 'Imagen',
  LABEL_INPUT_NO_IMAGE: 'No hay imagen',

  LABEL_BUTTON_SIGNIN: 'Iniciar sesión',
  LABEL_BUTTON_SAVE: 'Guardar',
  LABEL_BUTTON_CONTACT: 'Contáctenos',
  LABEL_BUTTON_DELETE: 'Eliminar',
  LABEL_BUTTON_CONFIRM: 'Confirmar',
  LABEL_BUTTON_CANCEL: 'Cancelar',
  LABEL_BUTTON_BACK: 'Atrás',

  LABEL_ADVERTISING_COUNTRY: 'País',
  LABEL_ADVERTISING_REGION: 'Región',
  LABEL_ADVERTISING_CITY: 'Ciudad',
  LABEL_ADVERTISING_WEBSITE: 'Sitio web',
  LABEL_ADVERTISING_AGE: 'Edad',
  LABEL_ADVERTISING_GENDER: 'Género',

  LABEL_WARNING_ADVERTISING: 'Por favor, complete el formulario de publicidad para activar la funcionalidad',

  ERROR_ENTER_NAME: 'Por favor, ingrese su nombre',
  ERROR_ENTER_PASSWORD: 'Por favor, ingrese su contraseña',
  ERROR_USER_NOT_FOUND: 'Usuario no encontrado, por favor verifique sus credenciales',

  LABEL_DASHBOARD_W: 'S',
  LABEL_DASHBOARD_MAN: 'Hombre',
  LABEL_DASHBOARD_NOT_DEFINED: 'No definido',
  LABEL_DASHBOARD_WOMAN: 'Mujer',
  LABEL_DASHBOARD_ACTIVE_CLIENTS: 'Clientes activos',
  LABEL_DASHBOARD_ACTIVE_CLIENTS_INFO: 'Clientes activos en el último mes',
  LABEL_DASHBOARD_NEW_CLIENTS: 'Nuevos clientes',
  LABEL_DASHBOARD_NEW_CLIENTS_INFO: 'Nuevos clientes en el último mes',
  LABEL_DASHBOARD_NUMBER_OF_CLIENTS: 'Número de clientes',
  LABEL_DASHBOARD_POINTS_GIVEN: 'Puntos otorgados',
  LABEL_DASHBOARD_AGE_DISTRIBUTION: 'Distribución por edad',
  LABEL_DASHBOARD_GENDER_DISTRIBUTION: 'Distribución por género',
  LABEL_DASHBOARD_POINTS_GIVEN_W: 'Puntos otorgados (Semanal)',
  LABEL_DASHBOARD_GIFTS_GIVEN_W: 'Regalos otorgados (Semanal)',
  LABEL_DASHBOARD_NO_DATA: 'Sin datos',

  LABEL_USERS_SEARCH: 'Buscar usuarios',
  LABEL_USERS_HEADER_NAME: 'Nombre visible',
  LABEL_USERS_HEADER_PLATFORM: 'Plataforma',
  LABEL_USERS_HEADER_CONNECTIONS: 'Conexiones',
  LABEL_USERS_HEADER_ACTIONS: 'Acción',

  LABEL_LOCALS_SEARCH: 'Buscar locales',
  LABEL_LOCALS_HEADER_NAME: 'Nombre visible',
  LABEL_LOCALS_HEADER_ADDRESS: 'Dirección',
  LABEL_LOCALS_HEADER_PHONE_NUMBER: 'Número de teléfono',

  LABEL_FEEDBACKS_SEARCH: 'Buscar retroalimentaciones',
  LABEL_FEEDBACKS_HEADER_USER: 'Nombre de usuario',
  LABEL_FEEDBACKS_HEADER_NOTE: 'Nota',
  LABEL_FEEDBACKS_HEADER_MESSAGE: 'Mensaje',
  LABEL_FEEDBACKS_HEADER_DATE: 'Fecha',

  LABEL_PRICING_CHOSE_PLAN: 'Elige tu plan mensual',
  LABEL_PRICING_SCALED_PLAN: 'Plan escalado',
  LABEL_PRICING_BASIC_TITLE: 'Plan Básico',
  LABEL_PRICING_BASIC_FEATURE_1: 'Acceso al módulo de administración',
  LABEL_PRICING_BASIC_FEATURE_2: 'Gestiona la configuración de tu empresa',
  LABEL_PRICING_BASIC_FEATURE_3: 'Gestiona tus usuarios (máx. 1 usuario)',
  LABEL_PRICING_BASIC_FEATURE_4: 'Visualiza tus retroalimentaciones',
  LABEL_PRICING_BASIC_FEATURE_5: 'Soporte básico',
  LABEL_PRICING_STANDARD_TITLE: 'Plan Estándar',
  LABEL_PRICING_STANDARD_FEATURE_1: 'Incluye el plan básico',
  LABEL_PRICING_STANDARD_FEATURE_2: 'Número ilimitado de usuarios',
  LABEL_PRICING_STANDARD_FEATURE_3: 'Hasta 1 Local',
  LABEL_PRICING_STANDARD_FEATURE_4: '1 Notificación',
  LABEL_PRICING_STANDARD_FEATURE_5: 'Análisis básicos (Puntos, Regalos, Clientes activos, Nuevos clientes)',
  LABEL_PRICING_STANDARD_FEATURE_6: 'Publicidad en la red Fidelize hasta 1000 impresiones',
  LABEL_PRICING_STANDARD_FEATURE_7: 'Soporte estándar',
  LABEL_PRICING_PREMIUM_TITLE: 'Plan Premium',
  LABEL_PRICING_PREMIUM_FEATURE_1: 'Incluye el plan estándar',
  LABEL_PRICING_PREMIUM_FEATURE_2: 'Hasta 3 Locales',
  LABEL_PRICING_PREMIUM_FEATURE_3: '4 Notificaciones',
  LABEL_PRICING_PREMIUM_FEATURE_4: '1 Encuesta',
  LABEL_PRICING_PREMIUM_FEATURE_5: 'Análisis avanzados (Distribución por género y edad)',
  LABEL_PRICING_PREMIUM_FEATURE_6: 'Publicidad en la red Fidelize hasta 2000 impresiones',
  LABEL_PRICING_PREMIUM_FEATURE_7: 'Soporte prioritario',
  LABEL_PRICING_OBJECT_INTERESTED: 'Interesado en',

  LABEL_IMAGE_REQUIREMENT: 'Tu imagen debe tener una relación de aspecto 2/3 (vertical) o 3/2 (horizontal) y menos de 2MB',
  LABEL_ADVERTISING_WARNING_CHOOSE: 'Debes elegir una opción',
  LABEL_ADVERTISING_WARNING_LOAD_IMAGE: 'Por favor, sube una imagen',
  LABEL_ADVERTISING_TITLE_PICTURE: 'Eliminar imagen',
  LABEL_ADVERTISING_ALERT_PICTURE: 'Por favor, confirma si deseas eliminar tu imagen',

  LABEL_NOTIFICATION_NO_NOTIFICATION: 'No hay notificaciones, por favor crea la primera',
  LABEL_NOTIFICATION_TITLE: 'Título',
  LABEL_NOTIFICATION_TITLE_DESC: 'Por favor, elige un título para la notificación',
  LABEL_NOTIFICATION_TITLE_ERROR: 'El título debe tener entre 5 y 60 caracteres y contener solo letras, cifras o espacios',
  LABEL_NOTIFICATION_STATUS: 'Estado',
  LABEL_NOTIFICATION_TYPE: 'Tipo',
  LABEL_NOTIFICATION_DATE_DESC: 'Y selecciona la fecha y hora en la que deseas enviarla',
  LABEL_NOTIFICATION_DATE: 'Fecha',
  LABEL_NOTIFICATION_DATE_ERROR: 'Por favor, selecciona una fecha y hora para enviar notificaciones',
  LABEL_NOTIFICATION_CONTENT_DESC: 'Luego escribe el contenido',
  LABEL_NOTIFICATION_CONTENT: 'Contenido',
  LABEL_NOTIFICATION_CONTENT_ERROR: 'El contenido debe tener entre 10 y 100 caracteres y contener solo letras, cifras o espacios',
  LABEL_NOTIFICATION_ANSWER_DESC: 'Indica posibles respuestas',
  LABEL_NOTIFICATION_ANSWER: 'Respuesta',
  LABEL_NOTIFICATION_ANSWER_ERROR: 'Debes indicar entre 2 y 5 respuestas (con 5 hasta 20 caracteres)',
  LABEL_NOTIFICATION_ACTION: 'Acción',
  LABEL_NOTIFICATION_STANDARD: 'Estándar',
  LABEL_NOTIFICATION_SURVEY: 'Encuesta',
  LABEL_NOTIFICATION_NO_DATE: 'Sin fecha',
  LABEL_NOTIFICATION_CREATED: 'La notificación ha sido creada',
  LABEL_NOTIFICATION_UPDATED: 'La notificación ha sido actualizada',
  LABEL_NOTIFICATION_TOOLTIP_TYPE: 'El tipo estándar es un mensaje de notificación simple, las encuestas son preguntas y necesitarás indicar posibles respuestas',
  LABEL_NOTIFICATION_TOOLTIP_DATE: 'No puedes elegir en la próxima hora, por favor indica algo después',
  LABEL_NOTIFICATION_TOOLTIP_ANSWER: 'Presiona la tecla enter/return para agregar tu respuesta',
  LABEL_NOTIFICATION_ALERT_DELETE_TITLE: 'Eliminar una notificación',
  LABEL_NOTIFICATION_ALERT_DELETE_CONTENT: 'Vas a eliminar esta notificación, por favor confirma'
};
export default dictionary;
