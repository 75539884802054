import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

export const Copyright = (props) => {
  return (
    <Typography variant="body2" color="#FFFFFF" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="https://fidelize.org/">
        Algorithm Solutions
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};
