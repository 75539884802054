import React, { useState, useEffect } from "react";
import { Typography, Grid, Paper } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyAction } from "../redux/actions/company";
import CustomTextField from "../form/CustomTextField";
import getConfig from "../config";
import strings from "../i18n/language";
import { useTheme } from "@mui/material/styles";
let config = getConfig();
let baseUrl = `${config.PROTOCOL}${config.SERVER}:${config.PORT}`;

const Company = () => {
  // State for editable fields
  const [name, setName] = useState();
  const [facebookUrl, setFacebookUrl] = useState();
  const [instagramUrl, setInstagramUrl] = useState();
  const [giftExpiration, setGiftExpiration] = useState();
  const [cardExpiration, setCardExpiration] = useState();
  const user = useSelector((state) => state.user.user);
  const company = useSelector((state) => state.company.company);
  const dispatch = useDispatch();
  const theme = useTheme();

  useEffect(() => {
    if (user) {
      dispatch(getCompanyAction(user.company_id));
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (company) {
      setName(company.name);
      setFacebookUrl(company.facebook);
      setInstagramUrl(company.instagram);
      setGiftExpiration(company.giftExpiration);
      setCardExpiration(company.cardExpiration);
    }
  }, [company]);

  return (
    <Paper
      sx={{ p: 2, width: "80%", backgroundColor: theme.palette.primary.main }}
    >
      <Grid container justifyContent="center" margin="normal" mt={2}>
        <Grid item xs={8} mt={2} display="flex">
          <CustomTextField
            margin="normal"
            fullWidth
            variant="filled"
            id="name"
            label={strings.LABEL_INPUT_NAME}
            name="name"
            value={name}
            onChange={setName}
            autoFocus
          />
        </Grid>
        <Grid item xs={8} mt={2} display="flex">
          <CustomTextField
            margin="normal"
            fullWidth
            variant="filled"
            id="facebook-url"
            label={strings.LABEL_INPUT_FACEBOOK_URL}
            name="facebook-url"
            value={facebookUrl}
            onChange={setFacebookUrl}
            autoFocus
          />
        </Grid>
        <Grid item xs={8} mt={2} display="flex">
          <CustomTextField
            margin="normal"
            fullWidth
            variant="filled"
            id="instagram-url"
            label={strings.LABEL_INPUT_INSTAGRAM_URL}
            name="instagram-url"
            value={instagramUrl}
            onChange={setInstagramUrl}
            autoFocus
          />
        </Grid>
        <Grid item xs={8} mt={2} display="flex">
          <CustomTextField
            margin="normal"
            fullWidth
            variant="filled"
            id="gift-expiration"
            label={strings.LABEL_INPUT_GIFT_EXPIRATION}
            name="gift-expiration"
            value={giftExpiration}
            onChange={setGiftExpiration}
            autoFocus
          />
        </Grid>
        <Grid item xs={8} mt={2} display="flex">
          <CustomTextField
            margin="normal"
            fullWidth
            variant="filled"
            id="card-expiration"
            label={strings.LABEL_INPUT_CARD_EXPIRATION}
            name="card-expiration"
            value={cardExpiration}
            onChange={setCardExpiration}
            autoFocus
          />
        </Grid>
        <Grid item xs={8} mt={2} display="flex">
          <Typography variant="h6" color="rgba(255, 255, 255, 0.7)">
            {strings.LABEL_INPUT_IMAGE}
          </Typography>
        </Grid>
        {/*<Grid item xs={8} mt={2} display="flex">
          <CustomImageUpload
            disabled
            maxSizeMb={2}
            validRatio={[
              { width: 320, height: 480 },
              { width: 480, height: 320 },
            ]}
            onImageChange={handleImageChange}
          />
        </Grid>*/}
        <Grid item xs={8} mt={2} display="flex">
          <img
            alt="Company"
            src={baseUrl + "/public/avatars/no_image.jpg"}
            style={{
              width: "100%",
              height: 300,
              borderRadius: 5,
            }}
          ></img>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Company;
