import axios from "axios";
import getConfig from "./config";
import { store } from "./store";
import { LOGIN } from "./redux/constants";

let config = getConfig();
let baseUrl = `${config.PROTOCOL}${config.SERVER}:${config.PORT}`;
const instance = axios.create({
  baseURL: `${baseUrl}/${config.API_VERSION}`,
  headers: {
    "Content-Type": "application/json",
  },
});

// Intercepteur pour les requêtes
instance.interceptors.request.use(
  (config) => {
    const token = store.getState().login.tokenAccess;
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Intercepteur pour les réponses
instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response) {
      const { status } = error.response;

      if (status === 500) {
        return Promise.reject(error.response.data);
      }

      if (status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        try {
          const refreshResponse = await axios.post(`${baseUrl}/token/refresh`, {
            user_id: store.getState().user.user.id,
          });
          const accessToken = refreshResponse.data;

          store.dispatch({
            type: LOGIN.UPDATE_TOKEN,
            payload: accessToken,
          });

          instance.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${accessToken}`;
          originalRequest.headers["Authorization"] = `Bearer ${accessToken}`;

          return axios(originalRequest);
        } catch (refreshError) {
          store.dispatch({
            type: LOGIN.LOGOUT,
          });
          return Promise.reject(refreshError);
        }
      }
    }

    return Promise.reject(error);
  }
);

export { instance, baseUrl };
