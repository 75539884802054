import { STATS } from "../constants";
import { instance } from "../../axios";

let getStatsAction = () => {
  return (dispatch) => {
    dispatch({
      type: STATS.GET_STATS,
    });
    instance
      .get("/stats/")
      .then((results) => {
        dispatch({
          type: STATS.GET_STATS_SUCCESS,
          payload: results.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: STATS.GET_STATS_FAILURE,
          payload: error,
        });
      });
  };
};

let cleanStatsAction = () => {
  return (dispatch) => {
    dispatch({
      type: STATS.CLEAN_STATS,
    });
  };
};

export { getStatsAction, cleanStatsAction };
