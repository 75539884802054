import { NOTIFICATIONS } from "../constants";

const initialState = {
  notification: null,
  isLoadingCreateNotification: false,
  isLoadingUpdateNotification: false,
  isLoadingGetNotifications: false,
  isLoadingDeleteNotification: false,
  total: 0,
  notifications: [],
  error: null,
};

const notificationsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case NOTIFICATIONS.DELETE_NOTIFICATION:
      return {
        ...state,
        isLoadingDeleteNotification: true,
      };
    case NOTIFICATIONS.DELETE_NOTIFICATION_FAILURE:
      return {
        ...state,
        isLoadingDeleteNotification: false,
        error: action.payload,
      };
    case NOTIFICATIONS.DELETE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isLoadingDeleteNotification: false,
        error: null,
      };
    case NOTIFICATIONS.GET_NOTIFICATIONS:
      return {
        ...state,
        isLoadingGetNotifications: true,
      };
    case NOTIFICATIONS.GET_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        isLoadingGetNotifications: false,
        error: action.payload,
      };
    case NOTIFICATIONS.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        isLoadingGetNotifications: false,
        notifications: action.payload.data,
        total: action.payload.total,
        error: null,
      };
    case NOTIFICATIONS.CREATE_NOTIFICATION:
      return {
        ...state,
        isLoadingCreateNotification: true,
      };
    case NOTIFICATIONS.CREATE_NOTIFICATION_FAILURE:
      return {
        ...state,
        isLoadingCreateNotification: false,
        error: action.payload,
      };
    case NOTIFICATIONS.CREATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isLoadingCreateNotification: false,
        notification: action.payload,
        error: null,
      };
    case NOTIFICATIONS.UPDATE_NOTIFICATION:
      return {
        ...state,
        isLoadingUpdateNotification: true,
      };
    case NOTIFICATIONS.UPDATE_NOTIFICATION_FAILURE:
      return {
        ...state,
        isLoadingUpdateNotification: false,
        error: action.payload,
      };
    case NOTIFICATIONS.UPDATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isLoadingUpdateNotification: false,
        notification: action.payload,
        error: null,
      };
    case NOTIFICATIONS.SET_NOTIFICATION:
      return {
        ...state,
        notification: action.payload,
      };
    case NOTIFICATIONS.CLEAN_NOTIFICATIONS:
      return initialState;
    default:
      return state;
  }
};

export default notificationsReducer;
