import { FEEDBACK } from "../constants";
import { instance } from "../../axios";

let getFeedbacksAction = () => {
  return (dispatch) => {
    dispatch({
      type: FEEDBACK.GET_FEEDBACK,
    });
    instance
      .get("/feedback")
      .then((results) => {
        dispatch({
          type: FEEDBACK.GET_FEEDBACK_SUCCESS,
          payload: results.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: FEEDBACK.GET_FEEDBACK_FAILURE,
          payload: error,
        });
      });
  };
};

export { getFeedbacksAction };
