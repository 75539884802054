import { FEEDBACK } from "../constants";

const initialState = {
  feedbacks: [],
  isLoadingFeedbacks: true,
  error: null,
};

const feedbacksReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case FEEDBACK.GET_FEEDBACK:
      return {
        ...state,
        isLoadingFeedbacks: true,
      };
    case FEEDBACK.GET_FEEDBACK_FAILURE:
      return {
        ...state,
        isLoadingFeedbacks: false,
        error: action.payload,
      };
    case FEEDBACK.GET_FEEDBACK_SUCCESS:
      return {
        ...state,
        isLoadingFeedbacks: false,
        feedbacks: action.payload,
        error: null,
      };
    default:
      return state;
  }
};

export default feedbacksReducer;
