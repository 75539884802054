import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Link, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { loginAction, cleanError } from "../redux/actions/login";
import Paper from "@mui/material/Paper";
import CssBaseline from "@mui/material/CssBaseline";
import { ReactComponent as Logo } from "../assets/images/pie.svg";
import CustomTextField from "../form/CustomTextField";
import { makeStyles } from "@mui/styles";
import strings from "../i18n/language";
const packageJson = require("../../package.json");

const useStyles = makeStyles((theme) => ({
  fontTitle: {
    color: "white",
    fontSize: 150,
    fontFamily: "FingerPaint, sans-serif",
  },
}));

function Login() {
  const dispatch = useDispatch();
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const error = useSelector((state) => state.login.error);
  const classes = useStyles();

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!username || !username.trim()) {
      toast.error(strings.ERROR_ENTER_NAME);
    } else if (!password || !password.trim()) {
      toast.error(strings.ERROR_ENTER_PASSWORD);
    } else {
      dispatch(loginAction({ email: username, password }));
    }
  };

  useEffect(() => {
    if (error) {
      if (error === "USER_NOT_FOUND") {
        toast.error(strings.ERROR_USER_NOT_FOUND);
      }
      dispatch(cleanError());
    }
  }, [dispatch, error]);
  return (
    <div>
      <ToastContainer position="bottom-right" />
      <Grid
        container
        component="main"
        sx={{ height: "100vh", backgroundColor: "primary.main" }}
      >
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Logo />
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={6}
          square
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "secondary.main",
          }}
        >
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div className={classes.fontTitle}>f</div>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <CustomTextField
                margin="normal"
                required
                fullWidth
                variant="filled"
                id="username"
                label={strings.LABEL_INPUT_USERNAME}
                name="username"
                autoComplete="off"
                onChange={setUsername}
                value={username}
                autoFocus
              />
              <CustomTextField
                margin="normal"
                required
                fullWidth
                variant="filled"
                name="password"
                label={strings.LABEL_INPUT_PASSWORD}
                type="password"
                id="password"
                onChange={setPassword}
                value={password}
                autoComplete="current-password"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {strings.LABEL_BUTTON_SIGNIN}
              </Button>
              <Typography variant="body2" color="#FFFFFF" align="center">
                {"Copyright © "}
                <Link color="inherit" href="https://fidelize.org/">
                  Algorithm Solutions
                </Link>{" "}
                {new Date().getFullYear()}
                {"."}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <div style={{ position: "absolute", bottom: 15, left: 15 }}>
        <Typography variant="body2" color="#FFFFFF" align="center">
          v{packageJson.version}
        </Typography>
      </div>
    </div>
  );
}

export default Login;
