import React from "react";
import { makeStyles } from "@mui/styles";
import { TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import FormControl from "@mui/material/FormControl";

const useStyles = makeStyles((theme) => ({
  dateTimePicker: {
    "& .MuiInputBase-root": {
      color: "white",
      backgroundColor: "rgba(255, 255, 255, 0.2)",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
      },
      "&:hover fieldset": {
        border: "none",
      },
      "&.Mui-focused fieldset": {
        border: "none",
      },
      "&:before": {
        borderBottom: "1px solid white",
      },
      "&:hover:before": {
        borderBottom: "2px solid white",
      },
      "&.Mui-focused:before": {
        borderBottom: "2px solid white",
      },
    },
    "& .MuiInputLabel-root": {
      color: "white",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "white",
    },
    "& .MuiSvgIcon-root": {
      color: "white",
    },
  },
}));

const CustomDateTimeField = ({
  margin,
  disabled,
  value,
  onChange,
  label,
  isValid,
}) => {
  const classes = useStyles();
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <FormControl fullWidth margin={margin} disabled={disabled}>
        <DateTimePicker
          value={value}
          onChange={onChange}
          disablePast
          className={classes.dateTimePicker}
          label={label}
          ampm={false}
          textField={<TextField error={!isValid} />}
          minutesStep={15}
        />
      </FormControl>
    </LocalizationProvider>
  );
};

export default CustomDateTimeField;
