import React from "react";
import { FormControlLabel, Checkbox, Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  color: "rgba(255, 255, 255, 0.7)",
  "&.Mui-checked": {
    color: "rgba(255, 255, 255, 0.7)",
  },
  "& .MuiSvgIcon-root": {
    fontSize: 28,
  },
}));

function CustomCheckboxSet({ title, onChange, value, defaultValues }) {
  const handleChange = (key, event) => {
    let newValue = { ...value };
    newValue[key] = event.target.checked;
    onChange(newValue);
  };
  return (
    <Box sx={{ width: "100%", color: "white" }} marginTop={2}>
      <Typography variant="h6" color="rgba(255, 255, 255, 0.7)">
        {title}
      </Typography>
      {Object.keys(defaultValues).map((key) => (
        <FormControlLabel
          key={key}
          control={
            <StyledCheckbox
              checked={value[key]}
              onChange={(event) => {
                handleChange(key, event);
              }}
              name={defaultValues[key]}
            />
          }
          label={defaultValues[key]}
        />
      ))}
    </Box>
  );
}

export default CustomCheckboxSet;
