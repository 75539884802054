import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { thunk } from "redux-thunk"; // Import named export `thunk`
import loginReducer from "./redux/reducers/login";
import userReducer from "./redux/reducers/user";
import companyReducer from "./redux/reducers/company";
import addressReducer from "./redux/reducers/address";
import feedbackReducer from "./redux/reducers/feedback";
import statsReducer from "./redux/reducers/stats";
import advertisingReducer from "./redux/reducers/advertising";
import notificationReducer from "./redux/reducers/notification";

const initialState = {};

const persistConfig = {
  key: "login",
  storage,
  blacklist: ["tokenAccess", "response", "stats"],
};

const rootReducer = combineReducers({
  login: loginReducer,
  notification: notificationReducer,
  user: userReducer,
  company: companyReducer,
  address: addressReducer,
  feedback: feedbackReducer,
  stats: statsReducer,
  advertising: advertisingReducer,
});

const logger = (store) => (next) => (action) => {
  const result = next(action);
  return result;
};

const middleware = [thunk, logger];

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  persistedReducer,
  initialState,
  composeEnhancers(applyMiddleware(...middleware))
);

const persistor = persistStore(store);

export { store, persistor };
