import { LOGIN } from "../constants";
import { instance, baseUrl } from "../../axios";
import { setUserAction, cleanUserAction } from "../actions/user";
import { cleanAdvertisingAction } from "../actions/advertising";
import { cleanNotificationAction } from "../actions/notification";
import { cleanStatsAction } from "../actions/stats";

// Public route
let loginAction = (data) => {
  return (dispatch) => {
    dispatch({
      type: LOGIN.LOGIN_REQUEST,
    });
    instance({
      method: "POST",
      url: baseUrl + "/token",
      data: {
        email: data.email,
        password: data.password,
        type: "web",
      },
    })
      .then((results) => {
        dispatch({
          type: LOGIN.LOGIN_SUCCESS,
          payload: results.data,
        });
        dispatch(setUserAction(results.data.User));
      })
      .catch((error) => {
        dispatch({
          type: LOGIN.LOGIN_FAILURE,
          payload: error,
        });
      });
  };
};

let logoutAction = () => {
  return (dispatch) => {
    dispatch(cleanUserAction());
    dispatch(cleanAdvertisingAction());
    dispatch(cleanNotificationAction());
    dispatch(cleanStatsAction());
    dispatch({
      type: LOGIN.LOGOUT,
    });
  };
};

let cleanError = () => {
  return (dispatch) => {
    dispatch({
      type: LOGIN.CLEAN_ERROR,
    });
  };
};

export { loginAction, logoutAction, cleanError };
