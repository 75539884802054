import React, { useState, useEffect } from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  IconButton,
  Chip,
  TablePagination,
  CircularProgress,
  Button,
} from "@mui/material";
import Fab from "@mui/material/Fab";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import dayjs from "dayjs";
import {
  getNotificationsAction,
  deleteNotificationAction,
  setNotificationAction,
} from "../../redux/actions/notification";
import { useDispatch, useSelector } from "react-redux";
import strings from "../../i18n/language";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AddIcon from "@mui/icons-material/Add";

const ROW_PER_PAGE = 10;
const setChipColor = (status) => {
  if (status === "planned") return "warning";
  if (status === "done") return "success";
};

const NotificationList = () => {
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();
  const [alertConfirm, setAlertConfirm] = useState(false);
  const [notificationDelete, setNotificationDelete] = useState(null);
  const navigate = useNavigate();

  const notifications = useSelector((state) => {
    return state.notification.notifications;
  });
  const notification = useSelector((state) => {
    return state.notification.notification;
  });
  const isLoadingGetNotifications = useSelector((state) => {
    return state.notification.isLoadingGetNotifications;
  });

  const total = useSelector((state) => {
    return state.notification.total;
  });

  useEffect(() => {
    dispatch(getNotificationsAction(ROW_PER_PAGE, 0));
  }, [dispatch]);

  useEffect(() => {
    if (notification) navigate("/notification/edit");
  }, [notification, navigate]);

  return (
    <>
      <Paper sx={{ p: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="Feedbacks table">
            <TableHead>
              <TableRow>
                <TableCell>{strings.LABEL_NOTIFICATION_TITLE}</TableCell>
                <TableCell>{strings.LABEL_NOTIFICATION_STATUS}</TableCell>
                <TableCell>{strings.LABEL_NOTIFICATION_TYPE}</TableCell>
                <TableCell>{strings.LABEL_NOTIFICATION_DATE}</TableCell>
                <TableCell>{strings.LABEL_NOTIFICATION_ACTION}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoadingGetNotifications && (
                <TableCell colSpan={5} align="center">
                  <CircularProgress />
                </TableCell>
              )}
              {notifications.length === 0 && !isLoadingGetNotifications && (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    {strings.LABEL_NOTIFICATION_NO_NOTIFICATION}
                  </TableCell>
                </TableRow>
              )}
              {notifications.map((notification) => (
                <TableRow key={notification.id}>
                  <TableCell component="th" scope="row">
                    {notification.title}
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={notification.status}
                      color={setChipColor(notification.status)}
                    />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {notification.type === "standard"
                      ? strings.LABEL_NOTIFICATION_STANDARD
                      : strings.LABEL_NOTIFICATION_SURVEY}
                  </TableCell>
                  <TableCell>
                    {dayjs(notification.datetime).isValid()
                      ? dayjs(notification.datetime).format("LLL")
                      : strings.LABEL_NOTIFICATION_NO_DATE}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="notification"
                      onClick={() => {
                        setAlertConfirm(true);
                        setNotificationDelete(notification);
                      }}
                      disabled={notification.status === "done"}
                    >
                      <DeleteIcon />
                    </IconButton>
                    {notification.status !== "done" && (
                      <IconButton
                        aria-label="edit"
                        onClick={() => {
                          dispatch(setNotificationAction(notification));
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[ROW_PER_PAGE]}
            count={total}
            rowsPerPage={ROW_PER_PAGE}
            page={page}
            onPageChange={(event, newPage) => {
              setPage(newPage);
              dispatch(
                getNotificationsAction(ROW_PER_PAGE, newPage * ROW_PER_PAGE)
              );
            }}
            component="div"
          />
        </TableContainer>
      </Paper>
      <Dialog
        open={alertConfirm}
        onClose={() => {
          setAlertConfirm(false);
        }}
        aria-labelledby="alert-dialog-delete-title"
        aria-describedby="alert-dialog-delete-description"
      >
        <DialogTitle id="alert-dialog-delete-title">
          {strings.LABEL_NOTIFICATION_ALERT_DELETE_TITLE}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-delete-description">
            {strings.LABEL_NOTIFICATION_ALERT_DELETE_CONTENT}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setAlertConfirm(false);
            }}
          >
            {strings.LABEL_BUTTON_CANCEL}
          </Button>
          <Button
            onClick={() => {
              dispatch(
                deleteNotificationAction(
                  notificationDelete.id,
                  ROW_PER_PAGE,
                  page
                )
              );
              setAlertConfirm(false);
            }}
          >
            {strings.LABEL_BUTTON_CONFIRM}
          </Button>
        </DialogActions>
      </Dialog>
      <Fab
        sx={{
          position: "absolute",
          bottom: 16,
          right: 16,
        }}
        color="primary"
        aria-label="add"
        onClick={() => {
          navigate("/notification/edit");
        }}
      >
        <AddIcon />
      </Fab>
    </>
  );
};

export default NotificationList;
