import { ADDRESS } from "../constants";
import { instance } from "../../axios";

let getAddressesAction = () => {
  return (dispatch) => {
    dispatch({
      type: ADDRESS.GET_ADDRESS,
    });
    instance
      .get("/address")
      .then((results) => {
        dispatch({
          type: ADDRESS.GET_ADDRESS_SUCCESS,
          payload: results.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: ADDRESS.GET_ADDRESS_FAILURE,
          payload: error,
        });
      });
  };
};

export { getAddressesAction };
