const getConfig = () => {
  let base = {
    PROTOCOL: 'https://',
    SERVER: 'fidelize.org',
    PORT: '443',
    API_VERSION: 'api/v1',
    HEADERS: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  return base;
};

export default getConfig;
