import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { GlobalStyles } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Dashboard from "./components/Dashboard";
import Login from "./components/Login";
import Users from "./components/Users";
import Locals from "./components/Locals";
import Feedbacks from "./components/Feedbacks";
import Protected from "./components/Protected";
import Company from "./components/Company";
import Advertising from "./components/Advertising";
import NotificationList from "./components/Notification/List";
import NotificationEdit from "./components/Notification/Edit";
import Pricing from "./components/Pricing";
import { useSelector } from "react-redux";
import { grey } from "@mui/material/colors";
import "./styles/global.css";

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#182B39",
    },
    secondary: {
      main: "#5DADE2",
    },
    background: {
      default: "#5DADE2",
    },
    text: {
      primary: "#000000",
    },
    warning: {
      main: "#E2925D",
    },
  },
  typography: {
    fontFamily: "Roboto, Arial, sans-serif",
  },
  components: {
    MuiInputBase: {
      defaultProps: {
        disableInjectingGlobalStyles: true,
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: grey[300],
          },
        },
      },
    },
  },
});

function App() {
  const isAuthenticated = useSelector((state) => state.login.tokenAccess);
  return (
    <div className="App">
      <ThemeProvider theme={defaultTheme}>
        <GlobalStyles
          styles={{
            "@keyframes mui-auto-fill": { from: { display: "block" } },
            "@keyframes mui-auto-fill-cancel": { from: { display: "block" } },
          }}
        />
        <CssBaseline />
        <Routes>
          {isAuthenticated ? (
            <>
              <Route path="/" element={<Protected />}>
                <Route index element={<Dashboard />} />
                <Route path="/company" element={<Company />} />
                <Route path="/users" element={<Users />} />
                <Route path="/locals" element={<Locals />} />
                <Route path="/feedbacks" element={<Feedbacks />} />
                <Route path="/pricing" element={<Pricing />} />
                <Route path="/advertising" element={<Advertising />} />
                <Route path="/notifications" element={<NotificationList />} />
                <Route
                  path="/notification/edit"
                  element={<NotificationEdit />}
                />
              </Route>
              {/* Add a default route for unmatched paths */}
              <Route path="*" element={<Navigate to="/" />} />
            </>
          ) : (
            <>
              <Route path="/" element={<Login />} />
              <Route path="*" element={<Navigate to="/" />} />
            </>
          )}
        </Routes>
      </ThemeProvider>
    </div>
  );
}

export default App;
