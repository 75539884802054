import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TextField,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import { getAddressesAction } from "../redux/actions/address";
import strings from "../i18n/language";

const Locals = () => {
  const addresses = useSelector((state) => state.address.addresses);
  const dispatch = useDispatch();
  const addressesRef = useRef([]);

  useEffect(() => {
    dispatch(getAddressesAction());
  }, [dispatch]);

  useEffect(() => {
    if (addresses) addressesRef.current = addresses;
  }, [addresses]);

  const [searchQuery, setSearchQuery] = useState("");

  const filteredLocals = addressesRef.current.filter(
    (local) =>
      local.label.toLowerCase().includes(searchQuery.toLowerCase()) ||
      local.address.toLowerCase().includes(searchQuery.toLowerCase()) ||
      local.phoneNumber.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Paper sx={{ p: 2 }}>
      <TextField
        fullWidth
        variant="outlined"
        placeholder={strings.LABEL_LOCALS_SEARCH}
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          sx: { borderRadius: 25 }, // Rounded corners for input field
        }}
        sx={{ mb: 2 }}
      />
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="Locals table">
          <TableHead>
            <TableRow>
              <TableCell>{strings.LABEL_LOCALS_HEADER_NAME}</TableCell>
              <TableCell>{strings.LABEL_LOCALS_HEADER_ADDRESS}</TableCell>
              <TableCell>{strings.LABEL_LOCALS_HEADER_PHONE_NUMBER}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredLocals.map((local) => (
              <TableRow key={local.id}>
                <TableCell component="th" scope="row">
                  {local.label}
                </TableCell>
                <TableCell>{local.address}</TableCell>
                <TableCell>{local.phoneNumber}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default Locals;
