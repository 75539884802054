import { COMPANY } from "../constants";
import { instance } from "../../axios";

let getCompanyAction = (id) => {
  return (dispatch) => {
    dispatch({
      type: COMPANY.GET_COMPANY,
    });
    instance
      .get("/company/" + id)
      .then((results) => {
        dispatch({
          type: COMPANY.GET_COMPANY_SUCCESS,
          payload: results.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: COMPANY.GET_COMPANY_FAILURE,
          payload: error,
        });
      });
  };
};

export { getCompanyAction };
