import React, { useState, useEffect } from "react";
import { Button, Grid, Typography, Paper, Box } from "@mui/material";
import CustomTextField from "../form/CustomTextField";
import CustomSelectField from "../form/CustomSelectField";
import CustomCheckboxSet from "../form/CustomCheckboxSet";
import CustomImageUpload from "../form/CustomImageUpload";
import WarningIcon from "@mui/icons-material/Warning";
import Tooltip from "@mui/material/Tooltip";
import {
  updateAdvertisingAction,
  getAdvertisingAction,
  cleanAdvertisingPictureAction,
} from "../redux/actions/advertising";
import { useDispatch, useSelector } from "react-redux";
import getConfig from "../config";
import strings from "../i18n/language";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
let config = getConfig();
let baseUrl = `${config.PROTOCOL}${config.SERVER}:${config.PORT}`;

const listCountries = [
  { value: 0, label: "Todos" },
  { value: 1, label: "Afganistán" },
  { value: 2, label: "Albania" },
  { value: 3, label: "Alemania" },
  { value: 4, label: "Andorra" },
  { value: 5, label: "Angola" },
  { value: 6, label: "Antigua y Barbuda" },
  { value: 7, label: "Arabia Saudita" },
  { value: 8, label: "Argelia" },
  { value: 9, label: "Argentina" },
  { value: 10, label: "Armenia" },
  { value: 11, label: "Australia" },
  { value: 12, label: "Austria" },
  { value: 13, label: "Azerbaiyán" },
  { value: 14, label: "Bahamas" },
  { value: 15, label: "Bangladés" },
  { value: 16, label: "Barbados" },
  { value: 17, label: "Baréin" },
  { value: 18, label: "Bélgica" },
  { value: 19, label: "Belice" },
  { value: 20, label: "Benín" },
  { value: 21, label: "Bielorrusia" },
  { value: 22, label: "Birmania" },
  { value: 23, label: "Bolivia" },
  { value: 24, label: "Bosnia y Herzegovina" },
  { value: 25, label: "Botsuana" },
  { value: 26, label: "Brasil" },
  { value: 27, label: "Brunéi" },
  { value: 28, label: "Bulgaria" },
  { value: 29, label: "Burkina Faso" },
  { value: 30, label: "Burundi" },
  { value: 31, label: "Bután" },
  { value: 32, label: "Cabo Verde" },
  { value: 33, label: "Camboya" },
  { value: 34, label: "Camerún" },
  { value: 35, label: "Canadá" },
  { value: 36, label: "Catar" },
  { value: 37, label: "Chad" },
  { value: 38, label: "Chile" },
  { value: 39, label: "China" },
  { value: 40, label: "Chipre" },
  { value: 41, label: "Colombia" },
  { value: 42, label: "Comoras" },
  { value: 43, label: "Corea del Norte" },
  { value: 44, label: "Corea del Sur" },
  { value: 45, label: "Costa de Marfil" },
  { value: 46, label: "Costa Rica" },
  { value: 47, label: "Croacia" },
  { value: 48, label: "Cuba" },
  { value: 49, label: "Dinamarca" },
  { value: 50, label: "Dominica" },
  { value: 51, label: "Ecuador" },
  { value: 52, label: "Egipto" },
  { value: 53, label: "El Salvador" },
  { value: 54, label: "Emiratos Árabes Unidos" },
  { value: 55, label: "Eritrea" },
  { value: 56, label: "Eslovaquia" },
  { value: 57, label: "Eslovenia" },
  { value: 58, label: "España" },
  { value: 59, label: "Estados Unidos" },
  { value: 60, label: "Estonia" },
  { value: 61, label: "Etiopía" },
  { value: 62, label: "Filipinas" },
  { value: 63, label: "Finlandia" },
  { value: 64, label: "Fiyi" },
  { value: 65, label: "Francia" },
  { value: 66, label: "Gabón" },
  { value: 67, label: "Gambia" },
  { value: 68, label: "Georgia" },
  { value: 69, label: "Ghana" },
  { value: 70, label: "Granada" },
  { value: 71, label: "Grecia" },
  { value: 72, label: "Guatemala" },
  { value: 73, label: "Guinea" },
  { value: 74, label: "Guinea-Bisáu" },
  { value: 75, label: "Guinea Ecuatorial" },
  { value: 76, label: "Guyana" },
  { value: 77, label: "Haití" },
  { value: 78, label: "Honduras" },
  { value: 79, label: "Hungría" },
  { value: 80, label: "India" },
  { value: 81, label: "Indonesia" },
  { value: 82, label: "Irak" },
  { value: 83, label: "Irán" },
  { value: 84, label: "Irlanda" },
  { value: 85, label: "Islandia" },
  { value: 86, label: "Islas Marshall" },
  { value: 87, label: "Islas Salomón" },
  { value: 88, label: "Israel" },
  { value: 89, label: "Italia" },
  { value: 90, label: "Jamaica" },
  { value: 91, label: "Japón" },
  { value: 92, label: "Jordania" },
  { value: 93, label: "Kazajistán" },
  { value: 94, label: "Kenia" },
  { value: 95, label: "Kirguistán" },
  { value: 96, label: "Kiribati" },
  { value: 97, label: "Kuwait" },
  { value: 98, label: "Laos" },
  { value: 99, label: "Lesoto" },
  { value: 100, label: "Letonia" },
  { value: 101, label: "Líbano" },
  { value: 102, label: "Liberia" },
  { value: 103, label: "Libia" },
  { value: 104, label: "Liechtenstein" },
  { value: 105, label: "Lituania" },
  { value: 106, label: "Luxemburgo" },
  { value: 107, label: "Macedonia del Norte" },
  { value: 108, label: "Madagascar" },
  { value: 109, label: "Malasia" },
  { value: 110, label: "Malaui" },
  { value: 111, label: "Maldivas" },
  { value: 112, label: "Malí" },
  { value: 113, label: "Malta" },
  { value: 114, label: "Marruecos" },
  { value: 115, label: "Mauricio" },
  { value: 116, label: "Mauritania" },
  { value: 117, label: "México" },
  { value: 118, label: "Micronesia" },
  { value: 119, label: "Moldavia" },
  { value: 120, label: "Mónaco" },
  { value: 121, label: "Mongolia" },
  { value: 122, label: "Montenegro" },
  { value: 123, label: "Mozambique" },
  { value: 124, label: "Namibia" },
  { value: 125, label: "Nauru" },
  { value: 126, label: "Nepal" },
  { value: 127, label: "Nicaragua" },
  { value: 128, label: "Níger" },
  { value: 129, label: "Nigeria" },
  { value: 130, label: "Noruega" },
  { value: 131, label: "Nueva Zelanda" },
  { value: 132, label: "Omán" },
  { value: 133, label: "Países Bajos" },
  { value: 134, label: "Pakistán" },
  { value: 135, label: "Palaos" },
  { value: 136, label: "Palestina" },
  { value: 137, label: "Panamá" },
  { value: 138, label: "Papúa Nueva Guinea" },
  { value: 139, label: "Paraguay" },
  { value: 140, label: "Perú" },
  { value: 141, label: "Polonia" },
  { value: 142, label: "Portugal" },
  { value: 143, label: "Reino Unido" },
  { value: 144, label: "República Centroafricana" },
  { value: 145, label: "República Checa" },
  { value: 146, label: "República del Congo" },
  { value: 147, label: "República Democrática del Congo" },
  { value: 148, label: "República Dominicana" },
  { value: 149, label: "Ruanda" },
  { value: 150, label: "Rumania" },
  { value: 151, label: "Rusia" },
  { value: 152, label: "Samoa" },
  { value: 153, label: "San Cristóbal y Nieves" },
  { value: 154, label: "San Marino" },
  { value: 155, label: "San Vicente y las Granadinas" },
  { value: 156, label: "Santa Lucía" },
  { value: 157, label: "Santo Tomé y Príncipe" },
  { value: 158, label: "Senegal" },
  { value: 159, label: "Serbia" },
  { value: 160, label: "Seychelles" },
  { value: 161, label: "Sierra Leona" },
  { value: 162, label: "Singapur" },
  { value: 163, label: "Siria" },
  { value: 164, label: "Somalia" },
  { value: 165, label: "Sri Lanka" },
  { value: 166, label: "Suazilandia" },
  { value: 167, label: "Sudáfrica" },
  { value: 168, label: "Sudán" },
  { value: 169, label: "Sudán del Sur" },
  { value: 170, label: "Suecia" },
  { value: 171, label: "Suiza" },
  { value: 172, label: "Surinam" },
  { value: 173, label: "Tailandia" },
  { value: 174, label: "Taiwán" },
  { value: 175, label: "Tanzania" },
  { value: 176, label: "Tayikistán" },
  { value: 177, label: "Timor Oriental" },
  { value: 178, label: "Togo" },
  { value: 179, label: "Tonga" },
  { value: 180, label: "Trinidad y Tobago" },
  { value: 181, label: "Túnez" },
  { value: 182, label: "Turkmenistán" },
  { value: 183, label: "Turquía" },
  { value: 184, label: "Tuvalu" },
  { value: 185, label: "Ucrania" },
  { value: 186, label: "Uganda" },
  { value: 187, label: "Uruguay" },
  { value: 188, label: "Uzbekistán" },
  { value: 189, label: "Vanuatu" },
  { value: 190, label: "Venezuela" },
  { value: 191, label: "Vietnam" },
  { value: 192, label: "Yemen" },
  { value: 193, label: "Yibuti" },
  { value: 194, label: "Zambia" },
  { value: 195, label: "Zimbabue" },
];

const regionsOfChile = [
  { value: 0, label: "Todas" },
  { value: 1, label: "Región de Arica y Parinacota" },
  { value: 2, label: "Región de Tarapacá" },
  { value: 3, label: "Región de Antofagasta" },
  { value: 4, label: "Región de Atacama" },
  { value: 5, label: "Región de Coquimbo" },
  { value: 6, label: "Región de Valparaíso" },
  { value: 7, label: "Región Metropolitana de Santiago" },
  { value: 8, label: "Región del Libertador General Bernardo O'Higgins" },
  { value: 9, label: "Región del Maule" },
  { value: 10, label: "Región de Ñuble" },
  { value: 11, label: "Región del Biobío" },
  { value: 12, label: "Región de La Araucanía" },
  { value: 13, label: "Región de Los Ríos" },
  { value: 14, label: "Región de Los Lagos" },
  { value: 15, label: "Región de Aysén del General Carlos Ibáñez del Campo" },
  { value: 16, label: "Región de Magallanes y de la Antártica Chilena" },
];

const comunasMetropolitanas = [
  { value: 0, label: "Todos" },
  { value: 1, label: "Cerrillos" },
  { value: 2, label: "Cerro Navia" },
  { value: 3, label: "Conchalí" },
  { value: 4, label: "El Bosque" },
  { value: 5, label: "Estación Central" },
  { value: 6, label: "Huechuraba" },
  { value: 7, label: "Independencia" },
  { value: 8, label: "La Cisterna" },
  { value: 9, label: "La Florida" },
  { value: 10, label: "La Granja" },
  { value: 11, label: "La Pintana" },
  { value: 12, label: "La Reina" },
  { value: 13, label: "Las Condes" },
  { value: 14, label: "Lo Barnechea" },
  { value: 15, label: "Lo Espejo" },
  { value: 16, label: "Lo Prado" },
  { value: 17, label: "Macul" },
  { value: 18, label: "Maipú" },
  { value: 19, label: "Ñuñoa" },
  { value: 20, label: "Pedro Aguirre Cerda" },
  { value: 21, label: "Peñalolén" },
  { value: 22, label: "Providencia" },
  { value: 23, label: "Pudahuel" },
  { value: 24, label: "Quilicura" },
  { value: 25, label: "Quinta Normal" },
  { value: 26, label: "Recoleta" },
  { value: 27, label: "Renca" },
  { value: 28, label: "San Joaquín" },
  { value: 29, label: "San Miguel" },
  { value: 30, label: "San Ramón" },
  { value: 31, label: "Santiago" },
  { value: 32, label: "Vitacura" },
  { value: 33, label: "Puente Alto" },
  { value: 34, label: "Pirque" },
  { value: 35, label: "San José de Maipo" },
  { value: 36, label: "Colina" },
  { value: 37, label: "Lampa" },
  { value: 38, label: "Tiltil" },
  { value: 39, label: "San Bernardo" },
  { value: 40, label: "Buin" },
  { value: 41, label: "Calera de Tango" },
  { value: 42, label: "Paine" },
  { value: 43, label: "Melipilla" },
  { value: 44, label: "Alhué" },
  { value: 45, label: "Curacaví" },
  { value: 46, label: "María Pinto" },
  { value: 47, label: "San Pedro" },
  { value: 48, label: "Talagante" },
  { value: 49, label: "El Monte" },
  { value: 50, label: "Isla de Maipo" },
  { value: 51, label: "Padre Hurtado" },
  { value: 52, label: "Peñaflor" },
];

const Advertising = () => {
  const dispatch = useDispatch();
  const [alertConfirm, setAlertConfirm] = useState(false);
  const [country, setCountry] = useState(38);
  const [region, setRegion] = useState(0);
  const [city, setCity] = useState(0);
  const [website, setWebsite] = useState("");
  const [genders, setGenders] = useState({
    0: true,
    1: false,
    2: false,
    3: false,
  });
  const [ages, setAges] = useState({
    0: true,
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
  });
  const [picture, setPicture] = useState({});
  const advertising = useSelector((state) => state.advertising.advertising);
  const theme = useTheme();

  useEffect(() => {
    dispatch(getAdvertisingAction());
  }, [dispatch]);

  useEffect(() => {
    if (advertising) {
      setCountry(advertising.country);
      setRegion(advertising.region);
      setCity(advertising.city);
      setWebsite(advertising.website);
      if (advertising.genders) setGenders(JSON.parse(advertising.genders));
      if (advertising.ages) setAges(JSON.parse(advertising.ages));
    }
  }, [advertising]);

  const handleImageChange = async (blob, name) => {
    setPicture({
      blob: blob,
      name: name,
    });
  };

  const handleSave = async () => {
    let formData = new FormData();
    if (picture) {
      formData.append("picture", picture.blob);
    }
    formData.append("country", country);
    formData.append("region", region);
    formData.append("city", city);
    formData.append("website", website);
    formData.append("genders", JSON.stringify(genders));
    formData.append("ages", JSON.stringify(ages));
    dispatch(updateAdvertisingAction(formData));
  };
  if (!advertising) return null;
  else
    return (
      <Paper
        sx={{ p: 2, width: "80%", backgroundColor: theme.palette.primary.main }}
      >
        <Grid container justifyContent="center" margin="normal" mt={2}>
          <Grid item xs={8} mt={2} display="flex">
            {" "}
            <CustomSelectField
              margin="normal"
              onChange={setCountry}
              title={strings.LABEL_ADVERTISING_COUNTRY}
              id={"country"}
              disabled
              value={country}
              defaultValues={listCountries}
              defaultValue={38}
            ></CustomSelectField>
          </Grid>
          <Grid item xs={8} mt={2} display="flex">
            <CustomSelectField
              margin="normal"
              onChange={setRegion}
              title={strings.LABEL_ADVERTISING_REGION}
              id={"region"}
              value={region}
              defaultValues={regionsOfChile}
              defaultValue={0}
            ></CustomSelectField>
          </Grid>
          <Grid item xs={8} mt={2} display="flex">
            <CustomSelectField
              margin="normal"
              onChange={setCity}
              title={strings.LABEL_ADVERTISING_CITY}
              id={"city"}
              value={city}
              defaultValues={comunasMetropolitanas}
              defaultValue={0}
            ></CustomSelectField>
          </Grid>
          <Grid
            item
            xs={8}
            mt={2}
            display="flex"
            style={{ alignItems: "center" }}
          >
            <CustomTextField
              margin="normal"
              fullWidth
              variant="filled"
              id="website"
              label={strings.LABEL_ADVERTISING_WEBSITE}
              name="website"
              value={website}
              onChange={setWebsite}
              autoFocus
            />
            {!advertising.website && (
              <Tooltip title={strings.LABEL_ADVERTISING_WARNING_CHOOSE}>
                <WarningIcon
                  style={{
                    marginLeft: 10,
                    fontSize: 30,
                    color: theme.palette.warning.main,
                  }}
                ></WarningIcon>
              </Tooltip>
            )}
          </Grid>
          <Grid
            item
            xs={8}
            mt={2}
            display="flex"
            style={{ alignItems: "center" }}
          >
            <CustomCheckboxSet
              value={genders}
              label={strings.LABEL_ADVERTISING_GENDER}
              title={strings.LABEL_ADVERTISING_GENDER}
              onChange={setGenders}
              defaultValues={{
                0: "Todos",
                1: "Hombres",
                2: "Mujeres",
                3: "No preciso",
              }}
            ></CustomCheckboxSet>
            {!advertising.genders ||
              (!Object.keys(JSON.parse(advertising.genders)).some(
                (k) => JSON.parse(advertising.genders)[k] !== false
              ) && (
                <Tooltip title={strings.LABEL_ADVERTISING_WARNING_CHOOSE}>
                  <WarningIcon
                    style={{
                      marginLeft: 10,
                      fontSize: 30,
                      color: theme.palette.warning.main,
                    }}
                  ></WarningIcon>
                </Tooltip>
              ))}
          </Grid>
          <Grid
            item
            xs={8}
            mt={2}
            display="flex"
            style={{ alignItems: "center" }}
          >
            <CustomCheckboxSet
              value={ages}
              label={strings.LABEL_ADVERTISING_AGE}
              title={strings.LABEL_ADVERTISING_AGE}
              onChange={setAges}
              defaultValues={{
                0: "Todos",
                1: "18-24",
                2: "25-34",
                3: "35-44",
                4: "45-54",
                5: "55-64",
                6: "65+",
                7: "Unknown",
              }}
            ></CustomCheckboxSet>
            {!advertising.ages ||
              (!Object.keys(JSON.parse(advertising.ages)).some(
                (k) => JSON.parse(advertising.ages)[k] !== false
              ) && (
                <Tooltip title={strings.LABEL_ADVERTISING_WARNING_CHOOSE}>
                  <WarningIcon
                    style={{
                      marginLeft: 10,
                      fontSize: 30,
                      color: theme.palette.warning.main,
                    }}
                  ></WarningIcon>
                </Tooltip>
              ))}
          </Grid>
          <Grid
            item
            xs={8}
            mt={2}
            display="flex"
            style={{ alignItems: "center" }}
          >
            <Typography variant="h6" color="rgba(255, 255, 255, 0.7)">
              {strings.LABEL_INPUT_IMAGE}
            </Typography>
            {!advertising.picture && (
              <Tooltip title={strings.LABEL_ADVERTISING_WARNING_LOAD_IMAGE}>
                <WarningIcon
                  style={{
                    marginLeft: 10,
                    fontSize: 30,
                    color: theme.palette.warning.main,
                  }}
                ></WarningIcon>
              </Tooltip>
            )}
            {advertising.picture && (
              <Button
                onClick={() => {
                  setAlertConfirm(true);
                }}
                style={{
                  color: theme.palette.error.main,
                }}
                variant="filled"
                startIcon={<DeleteIcon />}
                className="delete-button"
              >
                {strings.LABEL_BUTTON_DELETE}
              </Button>
            )}
          </Grid>
          <Grid
            item
            xs={8}
            mt={2}
            display="flex"
            style={{ alignItems: "center" }}
          >
            <Typography color="rgba(255, 255, 255, 0.7)" sx={{ mt: 2, mb: 2 }}>
              {strings.LABEL_IMAGE_REQUIREMENT}
            </Typography>
          </Grid>
          <Grid
            item
            xs={8}
            mt={2}
            display="flex"
            style={{ alignItems: "center" }}
          >
            {advertising.picture ? (
              <img
                alt="Advertising"
                src={baseUrl + "/public/advertising/" + advertising.picture}
                style={{
                  border: "2px solid #182B39",
                  opacity: 0.8,
                  borderRadius: 15,
                }}
              ></img>
            ) : (
              <Typography color="rgba(255, 255, 255, 0.7)">
                {strings.LABEL_INPUT_NO_IMAGE}
              </Typography>
            )}
          </Grid>
          <Grid
            item
            xs={8}
            mt={2}
            display="flex"
            style={{ alignItems: "center" }}
          >
            <CustomImageUpload
              maxSizeMb={2}
              validRatio={[
                { width: 320, height: 480 },
                { width: 480, height: 320 },
              ]}
              onImageChange={handleImageChange}
            />
          </Grid>
        </Grid>
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Box sx={{ flex: "1 1 auto" }} />

          <Button
            onClick={handleSave}
            type="submit"
            sx={{ mr: 1, color: "white" }}
          >
            {strings.LABEL_BUTTON_SAVE}
          </Button>
        </Box>
        <Dialog
          open={alertConfirm}
          onClose={() => {
            setAlertConfirm(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {strings.LABEL_ADVERTISING_TITLE_PICTURE}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {strings.LABEL_ADVERTISING_ALERT_PICTURE}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setAlertConfirm(false);
              }}
            >
              {strings.LABEL_BUTTON_CANCEL}
            </Button>
            <Button
              onClick={() => {
                dispatch(cleanAdvertisingPictureAction());
                setAlertConfirm(false);
              }}
            >
              {strings.LABEL_BUTTON_CONFIRM}
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    );
};

export default Advertising;
