// prettier-ignore
let dictionary = {
  LABEL_INPUT_USERNAME:'Username',
  LABEL_INPUT_PASSWORD:'Password',
  LABEL_INPUT_NAME:'Name',
  LABEL_INPUT_FACEBOOK_URL:'Facebook URL',
  LABEL_INPUT_INSTAGRAM_URL:'Instagram URL',
  LABEL_INPUT_GIFT_EXPIRATION:'Gift Expiration (days)',
  LABEL_INPUT_CARD_EXPIRATION:'Card Expiration (days)',
  LABEL_INPUT_IMAGE:'Image',
  LABEL_INPUT_NO_IMAGE:'There is no image',

  LABEL_BUTTON_SIGNIN:'Sign in',
  LABEL_BUTTON_SAVE:'Save',
  LABEL_BUTTON_CONTACT:'Contact Us',
  LABEL_BUTTON_DELETE:'Delete',
  LABEL_BUTTON_CONFIRM:'Confirm',
  LABEL_BUTTON_CANCEL:'Cancel',
  LABEL_BUTTON_BACK:'Back',

  LABEL_ADVERTISING_COUNTRY:'Country',
  LABEL_ADVERTISING_REGION:'Region',
  LABEL_ADVERTISING_CITY:'City',
  LABEL_ADVERTISING_WEBSITE:'Website',
  LABEL_ADVERTISING_AGE:'Age',
  LABEL_ADVERTISING_GENDER:'Gender',

  LABEL_WARNING_ADVERTISING:'Please fill the advertising form to activate the functionality',

  ERROR_ENTER_NAME:'Please enter your name',
  ERROR_ENTER_PASSWORD:'Please enter your password',
  ERROR_USER_NOT_FOUND:'User not found, please verify your credentials',

  LABEL_DASHBOARD_W:'W',
  LABEL_DASHBOARD_MAN:'Male',
  LABEL_DASHBOARD_NOT_DEFINED:'Not defined',
  LABEL_DASHBOARD_WOMAN:'Woman',
  LABEL_DASHBOARD_ACTIVE_CLIENTS: 'Active Clients',
  LABEL_DASHBOARD_ACTIVE_CLIENTS_INFO: 'Active clients in the last month',
  LABEL_DASHBOARD_NEW_CLIENTS: 'New Clients',
  LABEL_DASHBOARD_NEW_CLIENTS_INFO: 'New clients in the last month',
  LABEL_DASHBOARD_NUMBER_OF_CLIENTS:'Number of Clients',
  LABEL_DASHBOARD_POINTS_GIVEN:'Points Given',
  LABEL_DASHBOARD_AGE_DISTRIBUTION:'Age Distribution',
  LABEL_DASHBOARD_GENDER_DISTRIBUTION:'Gender Distribution',
  LABEL_DASHBOARD_POINTS_GIVEN_W:'Points Given (Weekly)',
  LABEL_DASHBOARD_GIFTS_GIVEN_W:'Gifts Given (Weekly)',
  LABEL_DASHBOARD_NO_DATA:'No data',

  LABEL_USERS_SEARCH:'Search users',
  LABEL_USERS_HEADER_NAME:'Display Name',
  LABEL_USERS_HEADER_PLATFORM:'Platform',
  LABEL_USERS_HEADER_CONNECTIONS:'Connections',
  LABEL_USERS_HEADER_ACTIONS:'Action',

  LABEL_LOCALS_SEARCH:'Search locals',
  LABEL_LOCALS_HEADER_NAME:'Display Name',
  LABEL_LOCALS_HEADER_ADDRESS:'Address',
  LABEL_LOCALS_HEADER_PHONE_NUMBER:'Phone Number',

  LABEL_FEEDBACKS_SEARCH:'Search feedbacks',
  LABEL_FEEDBACKS_HEADER_USER:'User Name',
  LABEL_FEEDBACKS_HEADER_NOTE:'Note',
  LABEL_FEEDBACKS_HEADER_MESSAGE:'Message',
  LABEL_FEEDBACKS_HEADER_DATE:'Date',

  LABEL_PRICING_CHOSE_PLAN:'Choose your monthly plan',
  LABEL_PRICING_SCALED_PLAN:'Scaled plan',
  LABEL_PRICING_BASIC_TITLE:'Plan Basic',
  LABEL_PRICING_BASIC_FEATURE_1:'Access to the administration module',
  LABEL_PRICING_BASIC_FEATURE_2:'Manage your company settings',
  LABEL_PRICING_BASIC_FEATURE_3:'Manage your users (max 1 user)',
  LABEL_PRICING_BASIC_FEATURE_4:'Visualize your feedbacks',
  LABEL_PRICING_BASIC_FEATURE_5:'Basic support',
  LABEL_PRICING_STANDARD_TITLE:'Plan Standard',
  LABEL_PRICING_STANDARD_FEATURE_1:'Include basic plan',
  LABEL_PRICING_STANDARD_FEATURE_2:'Number of users unlimited',
  LABEL_PRICING_STANDARD_FEATURE_3:'Up to 1 Local',
  LABEL_PRICING_STANDARD_FEATURE_4:'1 Notification',
  LABEL_PRICING_STANDARD_FEATURE_5:'Basic analytics (Points, Gifts, Active clients, New clients)',
  LABEL_PRICING_STANDARD_FEATURE_6:'Advertising on Fidelize network up to 1000 impressions',
  LABEL_PRICING_STANDARD_FEATURE_7:'Standard support',
  LABEL_PRICING_PREMIUM_TITLE:'Plan Premium',
  LABEL_PRICING_PREMIUM_FEATURE_1:'Include standard plan',
  LABEL_PRICING_PREMIUM_FEATURE_2:'Up to 3 Local',
  LABEL_PRICING_PREMIUM_FEATURE_3:'4 Notifications',
  LABEL_PRICING_PREMIUM_FEATURE_4:'1 Survey',
  LABEL_PRICING_PREMIUM_FEATURE_5:'Advanced analytics (Gender & Age distribution)',
  LABEL_PRICING_PREMIUM_FEATURE_6:'Advertising on Fidelize network up to 2000 impressions',
  LABEL_PRICING_PREMIUM_FEATURE_7:'Priority support',
  LABEL_PRICING_OBJECT_INTERESTED:'Interested in',

  LABEL_IMAGE_REQUIREMENT:'You image should have ration 2/3 (portrait) or 3/2 (landscape) and less than 2MB',
  LABEL_ADVERTISING_WARNING_CHOOSE:'You should choose an option',
  LABEL_ADVERTISING_WARNING_LOAD_IMAGE:'Please upload an image',
  LABEL_ADVERTISING_TITLE_PICTURE:'Remove picture',
  LABEL_ADVERTISING_ALERT_PICTURE:'Please confirm you want to remove your picture',

  LABEL_NOTIFICATION_NO_NOTIFICATION:' There is no notifications, please create your first one',
  LABEL_NOTIFICATION_TITLE:'Title',
  LABEL_NOTIFICATION_TITLE_DESC:'Please choose a title for the notification',
  LABEL_NOTIFICATION_TITLE_ERROR:'El titulo debe ser entre 5 y 60 caracteres y contener solo letras cifras o espacios',
  LABEL_NOTIFICATION_STATUS:'Status',
  LABEL_NOTIFICATION_TYPE:'Type',
  LABEL_NOTIFICATION_DATE_DESC:'And select the date and hour you want send it',
  LABEL_NOTIFICATION_DATE:'Date',
  LABEL_NOTIFICATION_DATE_ERROR:'Please select a date and time to send notifications',
  LABEL_NOTIFICATION_CONTENT_DESC:'Then write the content',
  LABEL_NOTIFICATION_CONTENT:'Content',
  LABEL_NOTIFICATION_CONTENT_ERROR:'El contenido debe ser entre 10 y 100 caracteres y contener solo letras, cifras o espacios',
  LABEL_NOTIFICATION_ANSWER_DESC:'Indicate possibles answer',
  LABEL_NOTIFICATION_ANSWER:'Answer',
  LABEL_NOTIFICATION_ANSWER_ERROR:'Debe indicar entre 2 y 5 respuestas (con 5 hasta 20 caracteres)',
  LABEL_NOTIFICATION_ACTION:'Action',
  LABEL_NOTIFICATION_STANDARD:'Standard',
  LABEL_NOTIFICATION_SURVEY:'Survey',
  LABEL_NOTIFICATION_NO_DATE: 'No Date',
  LABEL_NOTIFICATION_CREATED: 'Notification has been created',
  LABEL_NOTIFICATION_UPDATED: 'Notification has been updated',
  LABEL_NOTIFICATION_TOOLTIP_TYPE: 'Type standard is a simple notification message, survey are questions and you will need to indicate possible answers',
  LABEL_NOTIFICATION_TOOLTIP_DATE: 'You cannot choose in the next hour, please indicate something after',
  LABEL_NOTIFICATION_TOOLTIP_ANSWER: 'Press enter/return key to add your answer',
  LABEL_NOTIFICATION_ALERT_DELETE_TITLE:'Remove a notification',
  LABEL_NOTIFICATION_ALERT_DELETE_CONTENT:'You will remove this notification, please confirm'
};
export default dictionary;
