import React, { useState, useEffect } from "react";
import {
  Paper,
  Typography,
  Slide,
  Button,
  Box,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import CustomTextField from "../../form/CustomTextField";
import CustomSelectField from "../../form/CustomSelectField";
import CustomDateTimeField from "../../form/CustomDateTimeField";
import InfoIcon from "@mui/icons-material/Info";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import {
  createNotificationAction,
  updateNotificationAction,
  cleanNotificationAction,
} from "../../redux/actions/notification";
import Chip from "@mui/material/Chip";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import strings from "../../i18n/language";
import "react-toastify/dist/ReactToastify.css";
import _ from "lodash";

const listTypes = [
  { value: "standard", label: strings.LABEL_NOTIFICATION_STANDARD },
  { value: "survey", label: strings.LABEL_NOTIFICATION_SURVEY },
];

const NotificationEdit = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [show, setShow] = useState(true);
  const [title, setTitle] = useState(null);
  const [content, setContent] = useState(null);
  const [answer, setAnswer] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [type, setType] = useState("standard");
  const [dateTime, setDateTime] = useState(null);
  const [dirty, setDirty] = useState(false);
  const [errorTitle, setErrorTitle] = useState(true);
  const [errorAnswer, setErrorAnswer] = useState(true);
  const [errorContent, setErrorContent] = useState(true);
  const [errorDateTime, setErrorDateTime] = useState(true);
  const [updateNotification, setUpdateNotification] = useState(false);
  const [createNotification, setCreateNotification] = useState(false);
  const notification = useSelector((state) => state.notification.notification);

  const isLoadingUpdateNotification = useSelector((state) => {
    return state.notification.isLoadingUpdateNotification;
  });
  const isLoadingCreateNotification = useSelector((state) => {
    return state.notification.isLoadingCreateNotification;
  });
  const error = useSelector((state) => {
    return state.notification.error;
  });

  useEffect(() => {
    setCreateNotification(isLoadingCreateNotification);
    if (createNotification && !isLoadingCreateNotification && !error) {
      toast.info(strings.LABEL_NOTIFICATION_CREATED);
    }
  }, [isLoadingCreateNotification, error, createNotification]);

  useEffect(() => {
    setUpdateNotification(isLoadingUpdateNotification);
    if (updateNotification && !isLoadingUpdateNotification && !error) {
      toast.info(strings.LABEL_NOTIFICATION_UPDATED);
    }
  }, [isLoadingUpdateNotification, error, updateNotification]);

  useEffect(() => {
    if (notification) {
      setDirty(
        _.isEqual(notification, {
          ...notification,
          id: notification.id,
          status: notification.status,
          content: content,
          title: title,
          datetime: dateTime && dateTime.toISOString(),
          type: type,
          answers: answers && JSON.stringify(answers),
        })
      );
    }
  }, [notification, title, content, type, dateTime, answers]);

  useEffect(() => {
    if (notification) {
      setTitle(notification.title);
      setType(notification.type);
      if (notification.datetime) setDateTime(dayjs(notification.datetime));
      if (notification.answers) setAnswers(JSON.parse(notification.answers));
      setContent(notification.content);
    }
  }, [notification]);

  useEffect(() => {
    const alphanumericRegex = /^[a-z0-9\sáéíóúñüäöüß.,;:!?'"\-()]+$/i;
    if (title && title.length > 4 && title.length < 60) {
      if (alphanumericRegex.test(title)) {
        setErrorTitle(false);
      } else {
        setErrorTitle(true);
      }
    } else setErrorTitle(true);
  }, [title]);

  useEffect(() => {
    const alphanumericRegex = /^[a-z0-9\sáéíóúñüäöüß.,;:!?'"\-()]+$/i;
    if (content && content.trim().length > 10 && content.trim().length < 100) {
      if (alphanumericRegex.test(content)) {
        setErrorContent(false);
      } else {
        setErrorContent(true);
      }
    } else setErrorContent(true);
  }, [content]);

  useEffect(() => {
    const now = dayjs();
    const oneHourFromNow = now.add(1, "hour");
    if (
      !dateTime ||
      !dayjs(dateTime).isValid() ||
      dayjs(dateTime).isBefore(oneHourFromNow)
    ) {
      setErrorDateTime(true);
    } else {
      setErrorDateTime(false);
    }
  }, [dateTime]);

  const handleDelete = (chipToDelete) => () => {
    setAnswers((chips) => chips.filter((chip) => chip.id !== chipToDelete.id));
  };

  const save = () => {
    let status = "draft";
    if (type === "standard" && !errorContent && !errorTitle && !errorDateTime)
      status = "planned";
    if (
      type === "survey" &&
      !errorContent &&
      !errorTitle &&
      !errorDateTime &&
      !errorAnswer
    )
      status = "planned";
    if (type === "standard") setAnswers([]);
    if (notification && notification.id)
      dispatch(
        updateNotificationAction({
          id: notification.id,
          status: status,
          content: content,
          title: title,
          datetime: dateTime,
          type: type,
          answers: answers && JSON.stringify(answers),
        })
      );
    else
      dispatch(
        createNotificationAction({
          status: status,
          content: content,
          title: title,
          datetime: dateTime,
          type: type,
          answers: JSON.stringify(answers),
        })
      );
  };

  const handleBack = () => {
    dispatch(cleanNotificationAction());
    setShow(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const alphanumericRegex = /^[a-z0-9\s]+$/i;
      if (
        answer &&
        answer.length > 4 &&
        answer.length < 21 &&
        alphanumericRegex.test(answer)
      ) {
        if (answers.length) {
          setAnswers([
            ...answers,
            { id: answers[answers.length - 1].id + 1, label: answer },
          ]);
        } else {
          setAnswers([{ id: 0, label: answer }]);
        }
        setAnswer(null);
      }
    }
  };

  useEffect(() => {
    if (answers.length > 1 && answers.length < 6) setErrorAnswer(false);
    else setErrorAnswer(true);
  }, [answers]);

  return (
    <Slide
      direction="left"
      in={show}
      mountOnEnter
      unmountOnExit
      onExited={() => {
        navigate("/notifications");
      }}
      timeout={500}
    >
      <Paper
        sx={{ p: 2, width: "80%", backgroundColor: theme.palette.primary.main }}
      >
        <Grid container justifyContent="center" margin="normal" mt={2}>
          <Grid item xs={8} mt={2} display="flex">
            <CustomSelectField
              onChange={setType}
              title={strings.LABEL_NOTIFICATION_TYPE}
              id={"type"}
              value={type}
              defaultValues={listTypes}
              defaultValue={"standard"}
            ></CustomSelectField>
            <Tooltip title={strings.LABEL_NOTIFICATION_TOOLTIP_TYPE}>
              <IconButton style={{ color: "white" }}>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={8} mt={4}>
            <Typography color="rgba(255, 255, 255, 0.7)">
              {strings.LABEL_NOTIFICATION_TITLE_DESC}
            </Typography>
          </Grid>
          <Grid item xs={8} mt={2}>
            <CustomTextField
              fullWidth
              variant="filled"
              id="title"
              label={strings.LABEL_NOTIFICATION_TITLE}
              name="title"
              value={title}
              onChange={setTitle}
              autoFocus
            />
          </Grid>
          <Grid item xs={8} mt={1}>
            <Typography color={errorTitle ? "error" : "success.main"}>
              {strings.LABEL_NOTIFICATION_TITLE_ERROR}
            </Typography>
          </Grid>
          <Grid item xs={8} mt={4}>
            <Typography color="rgba(255, 255, 255, 0.7)">
              {strings.LABEL_NOTIFICATION_DATE_DESC}
            </Typography>
          </Grid>
          <Grid item xs={8} mt={2} display="flex">
            <CustomDateTimeField
              value={dateTime}
              onChange={setDateTime}
              isValid={errorDateTime}
              label={strings.LABEL_NOTIFICATION_DATE}
            />
            <Tooltip title={strings.LABEL_NOTIFICATION_TOOLTIP_DATE}>
              <IconButton style={{ color: "white" }}>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={8} mt={1}>
            <Typography color={errorDateTime ? "error" : "success.main"}>
              {strings.LABEL_NOTIFICATION_DATE_ERROR}
            </Typography>
          </Grid>
          <Grid item xs={8} mt={4}>
            <Typography color="rgba(255, 255, 255, 0.7)">
              {strings.LABEL_NOTIFICATION_CONTENT_DESC}
            </Typography>
          </Grid>

          <Grid item xs={8} mt={2}>
            <CustomTextField
              fullWidth
              variant="filled"
              id="content"
              label={strings.LABEL_NOTIFICATION_CONTENT}
              name="content"
              value={content}
              onChange={setContent}
              autoFocus
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={8} mt={1}>
            <Typography color={errorContent ? "error" : "success.main"}>
              {strings.LABEL_NOTIFICATION_CONTENT_ERROR}
            </Typography>
          </Grid>
          {type === "survey" && (
            <>
              <Grid item xs={8} mt={4}>
                <Typography color="rgba(255, 255, 255, 0.7)">
                  {strings.LABEL_NOTIFICATION_ANSWER_DESC}
                </Typography>
              </Grid>
              <Grid item xs={8} mt={2} display="flex">
                <CustomTextField
                  fullWidth
                  variant="filled"
                  id="answer"
                  label={"Answer"}
                  name="answer"
                  value={answer}
                  onChange={setAnswer}
                  autoFocus
                  autoComplete="off"
                  handleKeyDown={handleKeyDown}
                />
                <Tooltip title={strings.LABEL_NOTIFICATION_TOOLTIP_ANSWER}>
                  <IconButton style={{ color: "white" }}>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={8} mt={2}>
                {answers.map((data) => {
                  return (
                    <Chip
                      key={data.id}
                      color="secondary"
                      label={data.label}
                      style={{ marginRight: theme.spacing(2) }}
                      onDelete={handleDelete(data)}
                      sx={{ mb: 2 }}
                    />
                  );
                })}
              </Grid>
              <Grid item xs={8} mt={1}>
                <Typography color={errorAnswer ? "error" : "success.main"}>
                  {strings.LABEL_NOTIFICATION_ANSWER_ERROR}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Button onClick={handleBack} sx={{ mr: 1, color: "white" }}>
            {strings.LABEL_BUTTON_BACK}
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />

          <Button
            onClick={save}
            disabled={dirty}
            sx={{ mr: 1, color: "white" }}
          >
            {strings.LABEL_BUTTON_SAVE}
          </Button>
        </Box>
      </Paper>
    </Slide>
  );
};

export default NotificationEdit;
