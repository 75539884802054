import { COMPANY } from "../constants";

const initialState = {
  company: null,
  isLoadingGetCompany: true,
  error: null,
};

const companyReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case COMPANY.GET_COMPANY:
      return {
        ...state,
        isLoadingGetCompany: true,
      };
    case COMPANY.GET_COMPANY_FAILURE:
      return {
        ...state,
        isLoadingGetCompany: false,
        error: action.payload,
      };
    case COMPANY.GET_COMPANY_SUCCESS:
      return {
        ...state,
        isLoadingGetCompany: false,
        company: action.payload,
        error: null,
      };
    default:
      return state;
  }
};

export default companyReducer;
