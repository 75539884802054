import React, { useState } from "react";
import { Box, Typography, Input, styled } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const CustomImageUpload = styled(Box)(({ theme }) => ({
  border: "2px dashed",
  borderColor: "rgba(255, 255, 255, 0.7)",
  backgroundColor: "rgba(255, 255, 255, 0.2)",
  borderRadius: 8,
  padding: theme.spacing(4),
  textAlign: "center",
  cursor: "pointer",
  transition: "background-color 0.3s",
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.5)",
  },
}));

function ImageUpload({ maxSizeMb, validRatio, onImageChange, disabled }) {
  const [image, setImage] = useState(null);
  const [error, setError] = useState("");
  const [orientation, setOrientation] = useState(null);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileSizeMB = file.size / (1024 * 1024); // Convert bytes to MB
      if (fileSizeMB > maxSizeMb) {
        setError(`File size exceeds the limit of ${maxSizeMb}MB`);
        return;
      }

      const reader = new FileReader();
      reader.onloadend = async () => {
        const img = new Image();
        img.onload = async () => {
          const { width, height } = img;

          const isValidRatio = validRatio.some(
            (ratio) => width === ratio.width && height === ratio.height
          );

          if (!isValidRatio) {
            setError(
              `Dimensions are not right, your image is ${width}x${height}`
            );
            setImage(null);
          } else {
            setError("");
            setOrientation(height > width ? "portrait" : "landscape");
            setImage(img.src);
            const base64Response = await fetch(img.src);
            const blob = await base64Response.blob();
            try {
              onImageChange(blob, file.name);
            } catch (uploadError) {
              setError("Failed to upload image. Please try again.");
            }
          }
        };
        img.src = reader.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      handleFileChange({ target: { files: [file] } });
    }
  };

  return (
    <Box sx={{ width: "100%", marginTop: 2, marginBottom: 2 }}>
      <CustomImageUpload
        onDragOver={!disabled && handleDragOver}
        onDrop={!disabled && handleDrop}
        onClick={() =>
          !disabled && document.getElementById("file-upload").click()
        }
        disabled={disabled}
      >
        {image ? (
          <>
            <Typography variant="body2" color="success.main" sx={{ mt: 2 }}>
              Your image fits the requirements and will be used as {orientation}
            </Typography>
          </>
        ) : (
          <>
            <CloudUploadIcon color="action" fontSize="large" />
            <Typography variant="h6" gutterBottom color="white">
              Drag & Drop or Click to Upload
            </Typography>
          </>
        )}
        {error && (
          <Typography variant="body2" color="error" sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}
        <Input
          id="file-upload"
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          sx={{ display: "none" }}
        />
      </CustomImageUpload>
    </Box>
  );
}

export default ImageUpload;
