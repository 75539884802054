export const LOGIN = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGOUT: "LOGOUT",
  UPDATE_TOKEN: "UPDATE_TOKEN",
  CLEAN_ERROR: "CLEAN_ERROR",
};

export const USER = {
  SET_USER: "SET_USER",
  CLEAN_USER: "CLEAN_USER",
  GET_USERS: "GET_USERS",
  GET_USERS_FAILURE: "GET_USERS_FAILURE",
  GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
};

export const COMPANY = {
  GET_COMPANY: "GET_COMPANY",
  GET_COMPANY_FAILURE: "GET_COMPANY_FAILURE",
  GET_COMPANY_SUCCESS: "GET_COMPANY_SUCCESS",
};

export const ADDRESS = {
  GET_ADDRESS: "GET_ADDRESS",
  GET_ADDRESS_FAILURE: "GET_ADDRESS_FAILURE",
  GET_ADDRESS_SUCCESS: "GET_ADDRESS_SUCCESS",
};

export const FEEDBACK = {
  GET_FEEDBACK: "GET_FEEDBACK",
  GET_FEEDBACK_FAILURE: "GET_FEEDBACK_FAILURE",
  GET_FEEDBACK_SUCCESS: "GET_FEEDBACK_SUCCESS",
};

export const STATS = {
  GET_STATS: "GET_STATS",
  GET_STATS_FAILURE: "GET_STATS_FAILURE",
  GET_STATS_SUCCESS: "GET_STATS_SUCCESS",
  CLEAN_STATS: "CLEAN_STATS",
};

export const ADVERTISING = {
  POST_ADVERTISING: "POST_ADVERTISING",
  POST_ADVERTISING_FAILURE: "POST_ADVERTISING_FAILURE",
  POST_ADVERTISING_SUCCESS: "POST_ADVERTISING_SUCCESS",
  GET_ADVERTISING: "GET_ADVERTISING",
  GET_ADVERTISING_FAILURE: "GET_ADVERTISING_FAILURE",
  GET_ADVERTISING_SUCCESS: "GET_ADVERTISING_SUCCESS",
  CLEAN_ADVERTISING: "CLEAN_ADVERTISING",
  CLEAN_ADVERTISING_PICTURE: "CLEAN_ADVERTISING_PICTURE",
  CLEAN_ADVERTISING_PICTURE_FAILURE: "CLEAN_ADVERTISING_PICTURE_FAILURE",
  CLEAN_ADVERTISING_PICTURE_SUCCESS: "CLEAN_ADVERTISING_PICTURE_SUCCESS",
};

export const NOTIFICATIONS = {
  CREATE_NOTIFICATION: "CREATE_NOTIFICATION",
  CREATE_NOTIFICATION_FAILURE: "CREATE_NOTIFICATION_FAILURE",
  CREATE_NOTIFICATION_SUCCESS: "CREATE_NOTIFICATION_SUCCESS",
  UPDATE_NOTIFICATION: "UPDATE_NOTIFICATION",
  UPDATE_NOTIFICATION_FAILURE: "UPDATE_NOTIFICATION_FAILURE",
  UPDATE_NOTIFICATION_SUCCESS: "UPDATE_NOTIFICATION_SUCCESS",
  GET_NOTIFICATIONS: "GET_NOTIFICATIONS",
  GET_NOTIFICATIONS_FAILURE: "GET_NOTIFICATIONS_FAILURE",
  GET_NOTIFICATIONS_SUCCESS: "GET_NOTIFICATIONS_SUCCESS",
  DELETE_NOTIFICATION: "DELETE_NOTIFICATION",
  DELETE_NOTIFICATION_FAILURE: "DELETE_NOTIFICATION_FAILURE",
  DELETE_NOTIFICATION_SUCCESS: "DELETE_NOTIFICATION_SUCCESS",
  CLEAN_NOTIFICATIONS: "CLEAN_NOTIFICATIONS",
  SET_NOTIFICATION: "SET_NOTIFICATION",
};
